//==============================================================================
// Place an Order / About Us Section.
//==============================================================================


.signposts {
    // Links section.
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    
    @media #{$desktop} {
        padding-top: 50px;
        padding-bottom: 95px;
    }
    
    @media #{$xxxl} {
        padding-left: 0;
        padding-right: 0;
    }
    
    > .container { 
        // .signposts > .container
        max-width: 1058px;
        
        @media #{$desktop} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;            
        }
    }
}
    
.signpost {
    // Links
    width: 100%;
    height: 100%;
    max-width: 523px;
    max-height: 303px;
    min-height: 250px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    
    @media #{$mobile} {
        margin-left: auto;
        margin-right: auto;
    }
    
    &:hover {
        // .signpost:hover
        
        .signpost__content {
            // .signpost:hover .signpost__content
            background-color:rgba(black, 0.3);
        }
        
        .signpost__title {
            // .signpost:hover .signpost__title
            text-shadow: 0 0 10px black;
        }
        
        .signpost__button {
            // .signpost:hover .signpost__button
            background-color: $mla-purple;
            color: white;
        }
        
    }
    
    &__image {
        // .signpost__image
        position: relative;
        z-index: 0;
        height: 100%;
        width: 100%;
        padding-bottom: 56%;
        
        @media #{$less-than-sm} {
            padding-bottom: 100%;
        }
        
        > img {
            // .signpost__image > img
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__content {
        // .signpost__content
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        background-color: rgba(black, 0.6);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 24px;
        transition: 0.3s ease background-color;
    }
    
    &__title {
        // .signpost__title
        text-align: center;
        color: white;
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 1em;
        letter-spacing: 0.06em;
        
        @media #{$desktop} {
            font-size: 32px;
        }
    }
    
    &__buttons {
        // .signpost__buttons
        
        @media #{$desktop} { 
            display: flex;

        }
        
        form + form {
            // .signpost__buttons form + form
            
            @media #{$mobile} { 
                margin-top: 5px;
            }
            
            @media #{$desktop} { 
                margin-left: 20px;
            }
        }
    }
    
    &__button {
        // .signpost__button
        background-color: white;
        border: none;
        
        & + .button {
            // .signpost__button + .button
            margin-left: 20px;
        }
        
        @media #{$mobile} {
            background-color: $mla-purple;
            color: white;
        }
    }
}