//==============================================================================
// New Products Container.
//==============================================================================

.new-products {
    // Container for new products.
    padding: 20px;

    @media #{$desktop} {
        padding-bottom: 30px
    }

    @media #{$lg} {
        padding-bottom: 50px
    }

    &__grid {
        // Grid/row container.
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        margin-bottom: 12px;

        @media #{$xxl} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-bottom: 0;
        }
    }

    &__gallery {
        // .new-products__gallery
        // 2-column product tile container.
        
        @media #{$xxl} {
            display: grid;
            grid-template-columns: 1fr 40%;
            grid-gap: 12px;
        }
    }
    
    &__product-grid {
        // .new-products__product-grid
        // Container for product tiles.
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        
        @media #{$mobile} {
            margin-top: 12px;
        }
        
        .product-tile {
            // .new-products__product-grid .product-tile
            margin: 0;
        }
    }

    &__hero {
        // .new-products__hero
        // Large tile.

        @media #{$less-than-xxl} {
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }

    .product-tile {
        // .new-products .product-tile

        @media #{$less-than-xxl} {
            margin: 0;
        }
    }
}
