.hero-tile {
    // Larger product tile with extra text and image.
    display: block;
    position: relative;
    background-color: white;
    padding: 20px;
    color: black;
    margin: 0;
    height: 100%;
    
    &__image {
        // .hero-tile__image
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        
        @media #{$less-than-sm} {
            padding-bottom: 150%;
        }

        img {
            // .hero-tile__image img
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding: 0;
            max-height: none;
            max-width: none;
        }
    }
    
     &__text {
        // .hero-tile__text
        padding: 20px;
        background-color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60%;
        
        @media #{$less-than-sm} {
            width: 100%;
        }
        
        @media #{$desktop} {
            width: 100%;
        }
        
        @media #{$xl} {
            width: 60%;
        }
    }
    
    &__heading {
        // .hero-tile__heading
        font-weight: 600;
        color: $grey-20;
        text-transform: uppercase;
        margin-bottom: 1em;
    }
    
    &__sub-heading {
        // .hero-tile__sub-heading
        color: $grey-60;
        margin-bottom: 1em;
        @include line-clamp(4);
    }
}
