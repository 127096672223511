//******************************************************************************
// Related Products Tiles
//
//******************************************************************************

// Non-rendering library SASS.
@import "tools.vars";
@import "tools.mixins";

.product-tiles {
    padding: 25px 0 20px 0;

    @media #{$desktop} {
        padding: 30px 20px 0 20px;
    }

    &__title {
        // .product-tiles__title
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        padding-left: 20px;
        padding-bottom: 20px;

        @media #{$desktop} {
            padding-left: 0;
        }
    }

}

.product-tile {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 2px 3px 6px 0px rgba(black, 0.1);
    text-decoration: none;
    margin: 20px 0;

    &:hover {
        // .product-tile:hover
        box-shadow: 2px 3px 12px 0px rgba(black, 0.2);

        .product-tile__image-hover {
            // .product-tile:hover .product-tile__image-hover
            opacity: 1;
        }
    }

    @media #{$desktop} {
        padding: 20px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    &__image {
        // .product-tile__image
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey-96;
        position: relative;
        aspect-ratio: 2;

        img {
            // .product-tile__image img
            display: block;
            margin: auto;
            padding: 20px 0;
            max-width: 60%;
            max-height: 100px;
            object-fit: contain;

            @media #{$desktop} {
                padding: 20px 0;
                max-height: 160px;
            }
        }
    }

    &__image-hover {
        // .product-tile__image-hover
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: opacity 0.2s ease-in-out;

    }

    img.product-tile__full-bleed-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        padding: 0;
        max-width: none;
        max-height: none;
    }

    &__text {
        // .product-tile__text
        flex: 1;
        display: flex;
        flex-direction: column;

        @media #{$mobile} {
            padding: 8px 8px 16px 8px;
            min-height: 125px;
        }
    }

    &__title {
        // .product-tile__title
        font-size: 13px;
        font-weight: 600;
        color: $grey-20;
        line-height: 1.3;
        margin-bottom: 10px;

        @media #{$mobile} {
            @include line-clamp(2);
        }

        @media #{$desktop} {
            padding: 12px 0;
            font-size: 16px;
        }

        @media #{$xxl} {
            padding: 16px 0;
            font-size: 20px;
        }
    }

    &__product-code {
        // .product-tile__product-code
        font-size: 14px;
        color: $grey-60;
        padding-bottom: 28px;
    }

    &__more-btn {
        // .product-tile__more-btn
        align-self: flex-start;
        margin-top: auto;

        @media #{$mobile} {
            width: auto;
            display: inline-block;
            border: none;
            padding: 0;
            font-size: 14px;
            height: auto;
            line-height: 1;

            &:hover {
                // .product-tile__more-btn:hover
                background: transparent;
                color: $mla-purple;
            }
        }
    }

    &__description-text {
        // .product-tile__description-text
        color: $grey-60;
        margin-bottom: 28px;
        line-height: 1.6;

        p {
            // .product-tile__description-text p
            margin-bottom: 20px;
        }

        ul {
            // .product-tile__description-text ul
            margin-bottom: 15px;

            > li {
                // .product-tile__description-text ul > li
                margin-bottom: 5px;

                &:before {
                    // .product-tile__description-text ul > li:before
                    display: inline-block;
                    content: "";
                    width: 0;
                    height: 0;
                    padding: 3px;
                    background-color: $mla-purple;
                    margin-right: 15px;
                    border-radius: 50%;
                    position: relative;
                    top: -3px;
                }
            }
        }
    }

    * {
        // .product-tile *
        user-select: none;
    }

    &--new {
        // product-tile--new
        // Displays square 'NEW' badge in top-left.

        .product-tile__image {
            // product-tile--new .product-tile__image
            position: relative;

            &:before {
                // product-tile--new .product-tile__image:before
                // Square 'NEW' badge in top-left.
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                z-index: 3;
                top: 0;
                left: 0;
                width: 32px;
                height: 32px;
                background-color: $mla-purple;
                color: white;
                text-transform: uppercase;
                content: "NEW";
                font-size: 9px;

                @media #{$desktop} {
                    font-size: 14px;
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    &--2-column {
        // product-tile--2-column
        display: flex;
        flex-direction: row;
    }
}


a.product-tile:hover {
    // a.product-tile:hover

    @media #{$desktop} {
        .product-tile__more-btn {
            // .product-tile:hover .product-tile__more-btn
            background-color: $mla-purple;
            color: white;
        }
    }
}