// ******************************************************************************
// Hero.
//
// Banner section, with optional Heading & Sub-Heading, used at the top of the 
// Home page.
// ******************************************************************************

.hero {
    // Hero area.
    background: $grey-40;
    position: relative;
    position: relative;

    a {
        // .hero a
        color: inherit;
        text-decoration: none;
    }

    &__image {
        // .hero__image
        // Fits hero image to container.
        width: 100%;
        height: auto;
    }

    &__overlay {
        // .hero__overlay
        // Semi-transparent overlay text container.
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: rgba(black, 0.6);
        color: white;
        padding: 40px 25px;
        text-align: center;

        @media #{$desktop} {
            padding: 62px;
        }
    }

    &__heading {
        // .hero__heading
        // Main hero text heading.
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding-bottom: 0.5em;

        @media #{$desktop} {
            font-size: 48px;
            max-width: 35ch;
        }
    }

    &__sub-heading {
        // .hero__sub-heading
        // Secondary hero text.
        @include line-clamp(3);
        padding-bottom: 0 !important;
        max-width: 60%;
        max-width: 35ch;
        font-size: 18px;
        margin-bottom: 1.5em;
        line-height: 1.4;

        @media #{$desktop} {
            max-width: 65ch;
        }
    }

    &__button {
        // .hero__button
        width: auto;
    }
}


//==============================================================================
// Video Banner Wrapper.
//==============================================================================

.video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}