// ******************************************************************************
// Page-Home CSS.
//
// CSS required for home page elements on www.mlaccessories.co.uk
//
// ******************************************************************************

// Non-rendering library SASS.
@import 'tools.vars';
@import 'tools.mixins';

@import 'module.hero';
@import "module.hero-tile";
@import "module.product-tile";
@import "module.new-products-grid";
@import "module.signposts";
@import 'module.notification-message';


.page-body {
    color: $grey-60;

    p {
        // .page-body p
        line-height: 1.4;
        margin-bottom: 1em;
    }
}

//==============================================================================
// New Products Section.
//==============================================================================

.new-products {
    // New Products one third panel
    position: relative;
    background-color: $grey-96;
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @media #{$xxxl} {
        padding-left: 0;
        padding-right: 0;
    }

    &__heading {
        // .new-products__heading
        // "New Products" heading.
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        color: black;
        padding-bottom: 0.5em;
        letter-spacing: 0.05em;

        @media #{$desktop} {
            font-size: 24px;
        }
    }

    &__intro {
        // .new-products__intro
        // Intro text.
        max-width: 600px;
    }

    &__button {
        // .new-products__button
        // 'See our New Products' button.
        max-width: 270px;
        font-size: 14px;
        background-color: transparent;
        margin-bottom: 60px;
    }
}


//==============================================================================
// Testimonials.
//==============================================================================

.testimonials {
    // Testimonials homepage section.
    background-color: $grey-96;
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @media #{$desktop} {
        padding-bottom: 130px;
    }

    @media #{$xxxl} {
        padding-left: 0;
        padding-right: 0;
    }

    &__heading {
        // .testimonials__heading
        // "Testimonials" heading.
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        color: black;
        padding-bottom: 0.5em;
        letter-spacing: 0.05em;

        @media #{$desktop} {
            font-size: 24px;
        }
    }

    &__intro {
        // .testimonials__intro
        max-width: 600px;
        margin-bottom: 36px;
    }

    &__footer {
        // .testimonials__footer
        text-align: center;
    }

    &__grid {
        // Grid container for testimonials.

        @media #{$xl} {
            display: grid;
            grid-column-gap: 12px;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            margin-bottom: 64px;
        }
    }
}

.testimonial {
    // Home page testimonial panel.
    background: white;
    display: flex;
    flex-direction: column;
    padding: 30px 24px;
    line-height: 1.5;
    margin-bottom: 20px;

    &__icon {
        // .testimonial__icon
        align-self: flex-start;
        padding-bottom: 10px;

        @media #{$desktop} {
            padding-bottom: 30px;
        }
    }

    &__body {
        // .testimonial__body
        font-size: 28px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        color: black;

        @media #{$desktop} {
            font-size: 32px;
        }
    }

    &__citation {
        // .testimonial__citation
        padding-top: 20px;
        font-size: 16px;
        color: $mla-purple;
        margin-top: auto;

        @media #{$desktop} {
            padding-top: 66px;
        }
    }
}