// Notification message shown on Basket & Home page

.notification-messages {
    padding-top: 20px;

    .notification-message {
        // .notification-messages .notification-message

        // Scoped inside `.notification-messages` to avoid conflicts with the 
        // `.notification-message` class used by Validation.js & NotificationMessage.js.
        display: flex;
        justify-content: center;
        border-radius: 8px;
        box-shadow: 0 5px 12px -3px rgba(black, 0.1),
            0 0px 5px -3px rgba(black, 0.8);
        margin-bottom: 20px;
        padding: 10px 20px;

        &__icon {
            // .notification-messages .notification-message__icon
            // Container for icon.
            flex: 0 0 50px;
            display: flex;

            @media #{$desktop} {
                flex-basis: 75px;
            }

            &:before {
                // .notification-messages .notification-message__icon:before
                // Circle with icon.
                display: flex;
                width: 6px;
                border-radius: 3px;
                height: 100%;
                background: rgba($color-neutral, 0.85);
                content: "";
            }

            &:after {
                // .notification-messages .notification-message__icon:after
                // Vertical line.
                margin: 10px auto auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                background: rgba($color-neutral, 0.85);
                font-family: 'iconfont';
                content: "\e906";
                color: white;
                border-radius: 50%;
                font-size: 0.7em;
            }
        }

        &__text {
            // .notification-messages .notification-message__text
            flex: 1 1 auto;
            padding-right: 15px;
            color: $grey-53;
            padding: 10px 0;

            > p {
                margin: 0;
            }

            > p + p {
                // .notification-messages .notification-message__text > p + p
                // Add space between text paragraphs.
                margin: 10px 0 0 0;
            }
        }
    }
}